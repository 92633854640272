



































































































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
// .dialog-footers {
//   .el-textarea__inner {
//     resize: none;
//     min-height: 100px;
//   }
// }
