


















































































































































































































































































































































































































































































































































































.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
